import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import IconArrowDown from '../../../assets/svg/iconArrowDown.svg';
import trim from 'lodash/trim';

function NavMenu({ className, items }) {
  const location = useLocation();

  const isItemActive = ({ href, items }) => {
    let active = trim(location.pathname, '/') === trim(href, '/');
    if (!active && items?.length) {
      active = items.some((item) => isItemActive(item));
    }
    return active;
  };

  const toggleMenuItem = (e) => {
    e.currentTarget
      .closest('.nav-menu__item-wrapper')
      .classList.toggle('opened');
  };

  return (
    <nav className={`nav-menu ${className}`}>
      {items.map(({ title, href, items }, itemIndex) => (
        <div
          className="nav-menu__item-wrapper"
          key={`nav-menu__item-wrapper${itemIndex}`}
        >
          {href ? (
            <Link
              className={`nav-menu__item${
                isItemActive({ href, items }) ? '' : ''
              }`}
              to={href}
            >
              {title}
            </Link>
          ) : (
            <p
              onClick={(e) => {
                items?.length && toggleMenuItem(e);
              }}
              className={`nav-menu__item${
                isItemActive({ href, items }) ? '' : ''
              }`}
            >
              {title}
            </p>
          )}
          {items?.length && (
            <div className="nav-menu__expand-button" onClick={toggleMenuItem}>
              <IconArrowDown className="nav-menu__icon-arrow" />
            </div>
          )}
          {items && (
            <div className="nav-menu__submenu-wrapper">
              <div className="nav-menu__submenu">
                {items.map(({ title, href, description }, subitemIndex) => (
                  <Link
                    className={`nav-menu__subitem${
                      isItemActive({ href }) ? ' active' : ''
                    }`}
                    to={href}
                    key={`nav-menu__subitem${subitemIndex}`}
                  >
                    <div className="nav-menu__subitem-title">{title}</div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </nav>
  );
}

export default NavMenu;
