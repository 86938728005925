import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ href, children, ...props }) => {
  const isInternalLink = href?.indexOf('//') === -1 && href?.indexOf('mailto') === -1;
  const externalLinkProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  if (isInternalLink) {
    return React.createElement(GatsbyLink, { to: href, ...props }, children);
  } else {
    return React.createElement('a', { href: href, ...externalLinkProps, ...props }, children);
  }
}

export default Link
