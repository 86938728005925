import React, { useEffect, useLayoutEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Header from '../Header';
import Footer from '../Footer';
import Modal from 'react-modal';
import '../../scss/styles.scss';
import { Helmet } from 'react-helmet';
import { getParams } from '../../utils';

const Layout = ({ children }) => {
  useEffect(() => {
    getParams();
  }, []);

  if (typeof window !== 'undefined') {
    Modal.setAppElement(document.getElementById('___gatsby'));
  }

  let rootPath = `/`;
  if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
    rootPath = __PATH_PREFIX__ + `/`;
  }
  gsap.registerPlugin(ScrollTrigger);
  // useLayoutEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger);
  //   // gsap.defaults({ ease: 'none', duration: 1 });
  // }, []);
    


  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}>
        <script src="https://proedge.pwc.com/optanon_6.22.0.js" async type="text/javascript"></script>
        <link src="https://proedge.pwc.com/optanon_6.22.0.css" rel="stylesheet" type="text/css" />
        <meta name="msvalidate.01" content="EF8BCC2F644185CBD697F0DBB173DA4D" />
      </Helmet>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
