import React from 'react';
import PropTypes from 'prop-types';
import Twitter from '../../../assets/svg/twitter.svg';
import LinkedIn from '../../../assets/svg/linkedIn.svg';
import Facebook from '../../../assets/svg/facebook.svg';
import Instagram from '../../../assets/svg/instagram.svg';
import Youtube from '../../../assets/svg/youtube.svg';
import RSS from '../../../assets/svg/rss.svg';
import Link from '../Link';

export const SocialItemsData = [
  {
    slug: 'twitter',
    title: 'Twitter',
    component: <Twitter />,
    url: 'https://twitter.com/PwCUS',
    shareTo: (shareUrl, shareText) => {
      let twitterShareURL = 'http://twitter.com/share?';
      twitterShareURL += `text=${shareText}&`;
      twitterShareURL += `url=${encodeURIComponent(shareUrl)}`;
      let windowOptions =
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600';
      window.open(twitterShareURL, '', windowOptions);
    },
  },
  {
    slug: 'linkedin',
    title: 'LinkedIn',
    component: <LinkedIn />,
    url: 'https://www.linkedin.com/company/pwc/mycompany/?viewAsMember=true',
    shareTo: (shareUrl) => {
      let linkedInShareURL = `https://www.linkedin.com/sharing/share-offsite/`;
      linkedInShareURL += `?url=${encodeURIComponent(shareUrl)}`;
      let windowOptions =
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600';
      window.open(linkedInShareURL, '', windowOptions);
    },
  },
  {
    slug: 'facebook',
    title: 'Facebook',
    component: <Facebook />,
    url: '',
    shareTo: (shareUrl, shareText) => {
      let facebookShareURL = 'https://www.facebook.com/sharer/sharer.php?u=';
      facebookShareURL += encodeURIComponent(shareUrl);
      let windowOptions =
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600';
      window.open(facebookShareURL, '', windowOptions);
      return;
    },
  },
  {
    slug: 'instagram',
    title: 'Instagram',
    component: <Instagram />,
    url: '',
  },
  {
    slug: 'youtube',
    title: 'Youtube',
    component: <Youtube />,
    url: '',
  },
  {
    slug: 'rss',
    title: 'RSS Feed',
    component: <RSS />,
    url: 'https://proedge.pwc.com/rss.xml',
  },
];

const SocialIcon = ({
  title,
  url,
  shareTo,
  component,
  theme,
  isShareLink,
  shareText,
  shareUrl,
}) => {
  const linkProps = {
    href: url,
    title: title,
    target: '_title',
    rel: 'noreferrer noopener',
    className: 'social-icons__icon',
  };

  const shareProps = {
    onClick: () => shareTo(shareUrl, shareText),
    className: 'social-icons__icon',
  };

  const tagType = isShareLink ? 'button' : Link;
  const elementProps = isShareLink ? shareProps : linkProps;

  return (
    <div className={`social-icons__item social-icons__item--${theme}`}>
      {React.createElement(tagType, elementProps, component)}
    </div>
  );
};

const SocialIcons = ({ theme, items, ...rest }) => {
  return (
    <div className="social-icons">
      <div className="social-icons__inner">
        {items.map((item, index) => {
          return <SocialIcon theme={theme} {...item} {...rest} key={index} />;
        })}
      </div>
    </div>
  );
};

SocialIcons.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  isShareLink: PropTypes.bool,
};
SocialIcons.defaultProps = {
  items: SocialItemsData,
  isShareLink: false,
  theme: 'light',
};
export default SocialIcons;
