import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const ButtonInner = ({ children }) => {
  return (
    <span className="button__inner">
      <span className="button__inner-text">{children}</span>
      <span className="button__inner-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
          <path
            d="M1 10a1 1 0 01-.71-.29 1 1 0 010-1.42L3.59 5 .29 1.71A1 1 0 011.71.29l4 4a1 1 0 010 1.42l-4 4A1 1 0 011 10z"
            fill="#fff"
          />
        </svg>
      </span>
    </span>
  );
};

const ButtonContainer = React.forwardRef(
  ({ align, children, className }, ref) => {
    return (
      <div
        ref={ref}
        className={`button-container button-container--${align} ${
          className && className
        }`}
      >
        {children}
      </div>
    );
  },
);
ButtonContainer.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
};
ButtonContainer.defaultProps = {
  align: 'left',
};

const Button = (props) => {
  const {
    as,
    children,
    theme,
    className,
    variant,
    href,
    disabled,
    ...rest
  } = props;

  // Setup class names, include specific ones at the end to override
  const classNames = [
    'button',
    `button--${theme}`,
    `button--${variant}`,
    `button--${disabled ? 'disabled' : 'enabled'}`,
    className,
  ];
  // The stack of props we pass to the button
  const buttonProps = { className: classNames.join(' '), ...rest };
  const hasHref = typeof href === 'string';
  const isInternal = href?.indexOf('//') === -1;

  if (hasHref && isInternal) {
    // this is an INTERNAL link with an href attr so we're going to make it with a Gatsby Link
    // since it's a Gatsby Link, use to instead of href.
    return React.createElement(
      Link,
      { to: href, ...buttonProps },
      <ButtonInner>{children}</ButtonInner>,
    );
  } else if (hasHref) {
    // this is an external link.
    const externalLinkProps = {
      target: '_blank',
      rel: 'noopener noreferrer',
    };
    return React.createElement(
      'a',
      { href: href, ...externalLinkProps, ...buttonProps },
      <ButtonInner>{children}</ButtonInner>,
    );
  } else {
    // this is a button
    return React.createElement(
      as,
      { ...buttonProps, disabled: disabled },
      <ButtonInner>{children}</ButtonInner>,
    );
  }
};

Button.propTypes = {
  as: PropTypes.oneOf(['a', 'button']),
  theme: PropTypes.oneOf(['light', 'dark', 'transparent']),
  variant: PropTypes.oneOf(['default', 'header', 'inline', 'small']),
  disabled: PropTypes.bool,
  href: PropTypes.string,
};

Button.defaultProps = {
  as: 'button',
  theme: 'light',
  variant: 'default',
  disabled: null,
  href: null,
};

export { Button, ButtonContainer };
