import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

function getDimensions() {
  const { offsetWidth: width, offsetHeight: height } = document.querySelector(
    'body',
  );
  return {
    width,
    height,
  };
}

export default function getContainerSize() {
  const [dimensions, setDimensions] = useState({});

  const handleResize = () => {
    setDimensions(getDimensions());
  };

  useEffect(() => {
    const handleResizeDebounced = debounce(handleResize, 100);
    handleResize();
    const event =
      'onorientationchange' in window ? 'orientationchange' : 'resize';
    window.addEventListener(event, handleResizeDebounced);
    return () => {
      handleResizeDebounced.cancel();
      window.removeEventListener(event, handleResizeDebounced);
    };
  }, []);

  return dimensions;
}
